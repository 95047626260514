import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Protected from "./Protected";
import {Layout} from "antd";
import NavigationMenu from "./components/app/LeftSideBar/NavigationMenu";
import {TopMenu} from "./components/app/TopMenu/TopMenu";
import LoginPage from "./components/auth/LoginPage";
import MainWindow from "./components/app/MainWIndow";

const { Content } = Layout;

export default function App({isAuthenticated}) {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage/>}/>
          <Route
              path="*"
              element={
                <Protected isAuthenticated={isAuthenticated}>
                  <Layout style={{height:'100vh'}}>
                    {/*<LeftSideBar location={this.props.location} history={this.props.history}/>*/}
                    <NavigationMenu/>
                    <Layout className="site-layout">
                      <TopMenu/>
                      <Content
                          className="site-layout-background"
                          style={{
                            margin: '16px 16px 0px 16px',
                            padding: '16px 16px 16px 16px',
                            minHeight: 280,
                          }}>
                        <MainWindow/>
                      </Content>
                    </Layout>
                  </Layout>
                </Protected>
              }
          />
        </Routes>
      </BrowserRouter>
  )
}
