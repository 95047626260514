import React from "react";
import FilterInputTypes from "./FilterInputTypes";
import {Button, Drawer, Form, Input, Layout, Row, Select, Switch} from "antd";
import listUtils from "../../../utils/ListUtils";
import queryString from 'query-string';
import {CaretDownOutlined} from "@ant-design/icons";
import './filter_popup.css'
import {ButtonX} from "../ButtonX";
import CrudBloc from "../../../bloc/CrudBloc";
import FilterInputItems from "./FilterInputItems";
import {ListSelect} from "../../../utils/InputFieldComponents";
import {InputFields} from "../../../utils/InputFields";
import {MenuConfig} from "../../../utils/Constants";
import "../FormComponent/FormComponent.css";
import {v4 as uuidv4} from 'uuid';
import {withRouter} from "../../../withRouter";


const {Option} = Select;
const {Content} = Layout;
class FilterPopup extends React.Component {

    formRef = React.createRef();
    constructor(props) {
        super(props);

        const {location, queryFilterParams } = this.props;
        let filtersParams= listUtils.currentFilters(location.search, queryFilterParams);
        this.state = {
            ...filtersParams,
            _visible: false,
        };
    }

    componentDidMount() {
        this.props.queryFilterParams.forEach(e => {
            switch (e) {
                case FilterInputItems.region_id.name:
                    CrudBloc.instance.getList({status: 1}, MenuConfig.region);
                    break;
                case FilterInputItems.category_id.name:
                    CrudBloc.instance.getList({status: 1, limit: 100}, MenuConfig.category);
                    break;
            }});


    }

    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    };

    handleChangeNameLess(name, value) {
        this.setState({[name]: value});
        //this.formRef.current?.setFieldsValue({ region_id: 2 });
    }

    handleOpen = () => this.setState({_visible: true});

    handleClose = () => this.setState({_visible: false});

    prepareOptions(options) {
        return options.map(e => <Option key={e.name + e.value + uuidv4()} value={`${e.value}`}>{e.title}</Option>);
    }

    resetFilters = () => {
        let oldState = this.state;
        Object.keys(oldState).forEach(k => {
            if (k !== "_visible") {
                oldState[k] = "";
            }
            this.formRef.current?.setFieldsValue({ [k]: undefined });
        });
        this.setState(oldState);
    };

    handleSearch = () => {
        const {queryFilterParams, history } = this.props;
        this.handleClose();
        let pathname = this.props.location.pathname;
        let searchParams = new URLSearchParams(this.props.location.search);
        queryFilterParams.forEach(e => {
            if(this.state[e]) searchParams.set(e, this.state[e]);
            else searchParams.delete(e);
        } );
        searchParams.set("page", "1");
        this.props.navigate({
            pathname: pathname,
            search: searchParams.toString(),
        });
    };


    handleCancel = () => {
        this.handleClose();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        if(prevProps.location !== this.props.location) {
            const values = queryString.parse(this.props.location.search);
            const { queryFilterParams } = this.props;
            queryFilterParams.forEach(e => {
                if (!values[e])
                    this.handleChangeNameLess(e, "");
            });

        }
    }

    callList(fieldName, menuName, value, filter, filterParam){

        let formRef = this.formRef

        if(this.props.queryFilterParams.some( e => e === fieldName.name)) {
            CrudBloc.instance.clearList(menuName);
            CrudBloc.instance.getList(filter, menuName, filterParam);
            formRef.current?.setFieldsValue({ area_id: undefined });
        }

    }


    render() {

        const {location, filterParams} = this.props;
        let formParams = listUtils.searchModalFormParams(location.search, filterParams);

        const form = <Content style={{width: 200}}>
            <Row>
                <Form layout="vertical" className={"filter-form"} ref={this.formRef}>
                    {
                        formParams.map((e, i) => {
                            switch (e.type) {
                                case FilterInputTypes.TEXT_INPUT:
                                    return (
                                        <Form.Item key={e.name} label={e.label}>
                                            <Input style={{height: 44, borderRadius: 5}} name={e.name}
                                                   placeholder={e.placeholder} value={this.state[e.name]}
                                                   onChange={this.handleChange}/>
                                        </Form.Item>
                                    );
                                case FilterInputTypes.SELECT_INPUT:
                                    switch (e.name) {
                                        case FilterInputItems.region_id.name:
                                            return <ListSelect key={e.name}
                                                               form={this.formRef}
                                                               name={MenuConfig.region.lower}
                                                               config={InputFields.region_id}
                                                               value={this.state[e.name] ? `${this.state[e.name]}` : undefined}
                                                               isFilter={true}
                                                               onClear={() => this.handleChangeNameLess(e.name, undefined)}
                                                               onSelect={(value) => {
                                                                   this.handleChangeNameLess(e.name, value);
                                                                   this.callList(InputFields.territory_id, MenuConfig.territory.lower, value, {
                                                                       status: 1,
                                                                       region_id: value
                                                                   }, MenuConfig.territory.filter);
                                                               }}
                                                               stream={CrudBloc.instance.regionList}
                                            />
                                        case FilterInputItems.territory_id.name:
                                            return <ListSelect key={e.name}
                                                               name={MenuConfig.territory.lower}
                                                               config={InputFields.territory_id}
                                                               value={this.state[e.name] ? this.state[e.name] : undefined}
                                                               isFilter={true}
                                                               onClear={() => this.handleChangeNameLess(e.name, undefined)}
                                                               onSelect={(value) => {
                                                                   this.handleChangeNameLess(e.name, value)
                                                               }}
                                                               stream={CrudBloc.instance.territoryList}
                                            />
                                        case FilterInputItems.category_id.name:
                                            return <ListSelect key={e.name}

                                                               name={MenuConfig.category.lower}
                                                               config={InputFields.category_id}
                                                               value={this.state[e.name] ? this.state[e.name] : undefined}
                                                               isFilter={true}
                                                               onClear={() => this.handleChangeNameLess(e.name, undefined)}
                                                               onSelect={(value) => {
                                                                   this.handleChangeNameLess(e.name, value);

                                                                   this.callList(InputFields.brand_id, MenuConfig.institution.lower, value, {
                                                                       status: 1,
                                                                       category_id: value
                                                                   }, MenuConfig.institution.filter);

                                                               }}
                                                               stream={CrudBloc.instance.categoryList}
                                            />
                                        case FilterInputItems.brand_id.name:
                                            return <ListSelect key={e.name}

                                                               name={MenuConfig.institution.lower}
                                                               config={InputFields.brand_id}
                                                               value={this.state[e.name] ? this.state[e.name] : undefined}
                                                               isFilter={true}
                                                               onClear={() => this.handleChangeNameLess(e.name, undefined)}
                                                               onSelect={(value) => {
                                                                   this.handleChangeNameLess(e.name, value);
                                                               }}
                                                               stream={CrudBloc.instance.brandList}
                                            />
                                        default:
                                            return <Form.Item key={e.name + uuidv4()} label={e.label} hasFeedback>
                                                <Select
                                                    showSearch
                                                    allowClear={true}
                                                    name={e.name}
                                                    size='large'
                                                    defaultValue={this.state[e.name] ? this.state[e.name] : undefined}
                                                    placeholder={e.placeholder ? e.placeholder : ""}
                                                    onSelect={(value) => this.handleChangeNameLess(e.name, value)}
                                                    onClear={() => this.handleChangeNameLess(e.name, undefined)}
                                                >
                                                    {this.prepareOptions(e.options)}
                                                </Select>
                                            </Form.Item>
                                    }
                                case FilterInputTypes.TOGGLE_INPUT:
                                    return (
                                        <Switch defaultChecked
                                                onChange={(evt) => this.handleChangeNameLess(e.key, !this.state[e.key])}/>

                                    );
                                default:
                                    alert("not supported yet");
                                    return null;
                            }

                        })
                    }
                </Form>
            </Row>
            <Row type="flex" justify="space-between" style={{marginTop: 16}} align="middle">

                <Button type="link" size="small" onClick={this.resetFilters}> Reset </Button>
                <ButtonX
                    htmlType="button"
                    name="cancel"  onClick={this.handleCancel}
                    text="Cancel" className={"button-default-cancel-small"}/>
                <ButtonX
                    htmlType="button"
                    name="apply"  onClick={this.handleSearch}
                    text="Apply" className={"button-default-accent-small"}/>
            </Row>
        </Content>;

        return  <div>
            <ButtonX
                style={{height: 40}}
                onClick={this.handleOpen}
                iconRight={<CaretDownOutlined/>}
                text={"Filter"} className={"button-filter"}/>
            <Drawer
            title="Filter"
            placement="right"
            closable={true}
            onClose={this.handleClose}
            open={this.state._visible}
        >
                {form}
        </Drawer>
        </div>
    }

    getCurrentProgram = () => {
        return this.props.location.pathname.split("/")[1];
    }
}

export default withRouter(FilterPopup)



