import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const monitoringDataConstants = {
  filters: [
    FilterInputItems.id,
    FilterInputItems.title,
    FilterInputItems.status
  ],
  listAttributes: [
    {
      title: 'ID',
      field: 'id',
      type: tableContentType.ID,
    },
    {
      title: 'Outlet Code',
      field: 'outlet_code',
      type: tableContentType.TEXT,
    },
    {
      title: 'Outlet Name',
      field: 'outlet_name',
      type: tableContentType.TEXT,
    },
    {
      title: 'ASE Name',
      field: 'agent_name',
      type: tableContentType.TEXT,
    },
    {
      title: 'Nutrition Store',
      field: 'is_nutrition_store',
      type: tableContentType.YES_NO,
    },
    {
      title: 'Hygiene Payment',
      field: 'is_hygiene_payment',
      type: tableContentType.YES_NO,
    },
    {
      title: 'Shelf Talker',
      field: 'is_shelf_talker',
      type: tableContentType.YES_NO,
    },
    {
      title: '# of Shelves',
      field: 'shelves',
      type: tableContentType.TEXT,
    },
    {
      title: 'Order MTD',
      field: 'order_mtd',
      type: tableContentType.TEXT,
    },
    {
      title: 'FIFO',
      field: 'fifo',
      type: tableContentType.YES_NO,
    },
    {
      title: 'Comment',
      field: 'comment',
      type: tableContentType.TEXT,
    },
    {
      title: 'Created At',
      field: 'created_at',
      type: tableContentType.DATE_TIME,
    },
    {
      title: 'Action',
      field: 'action',
      type: tableContentType.ACTION,
    },
  ],
  detailsAttributes: [
    {
      title: 'ID',
      field: 'id',
      type: tableContentType.TEXT,
    },
    {
      title: 'Outlet Code',
      field: 'outlet_code',
      type: tableContentType.TEXT,
    },
    {
      title: 'Outlet Name',
      field: 'outlet_name',
      type: tableContentType.TEXT,
    },
    {
      title: 'ASE Name',
      field: 'agent_name',
      type: tableContentType.TEXT,
    },
    {
      title: 'Nutrition Store',
      field: 'is_nutrition_store',
      type: tableContentType.YES_NO,
    },
    {
      title: 'Hygiene Payment',
      field: 'is_hygiene_payment',
      type: tableContentType.YES_NO,
    },
    {
      title: 'Shelf Talker',
      field: 'is_shelf_talker',
      type: tableContentType.YES_NO,
    },
    {
      title: '# of Shelves',
      field: 'shelves',
      type: tableContentType.TEXT,
    },
    {
      title: 'Order MTD',
      field: 'order_mtd',
      type: tableContentType.TEXT,
    },
    {
      title: 'FIFO',
      field: 'fifo',
      type: tableContentType.YES_NO,
    },
    {
      title: 'Comment',
      field: 'comment',
      type: tableContentType.TEXT,
    },
    {
      title: 'Created At',
      field: 'created_at',
      type: tableContentType.DATE_TIME,
    },
  ]
}



