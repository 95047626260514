import FilterInputTypes from "./FilterInputTypes";
import {InputFieldOptions} from "../../../utils/InputFieldOptions";
import Utils from "../../../utils/Utils";

export default {
  name: {
    type: FilterInputTypes.TEXT_INPUT,
    name: "name",
    required: false,
    label: `Name`,
    placeholder: `Enter name`,
  },
  user_id: {
    type: FilterInputTypes.TEXT_INPUT,
    name: "user_id",
    required: false,
    label: `Email`,
    placeholder: `Enter email`,
  },
  region_id: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "region_id",
    required: false,
    label: `Region`,
    placeholder: `Select region`,
    options: []
  },
  area_id: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "area_id",
    required: false,
    label: `Area`,
    placeholder: `Select area`,
    options: []
  },
  territory_id: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "territory_id",
    required: false,
    label: `Territory`,
    placeholder: `Select territory`,
    options: []
  },
  town_id: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "town_id",
    required: false,
    label: `Town`,
    placeholder: `Select town`,
    options: []
  },

  // call_type_id: {
  //   type: FilterInputTypes.SELECT_INPUT,
  //   name: "call_type_id",
  //   required: false,
  //   label: `Call Type`,
  //   placeholder: `Select call type`,
  //   options: []
  // },
  // root_cause_type_id: {
  //   type: FilterInputTypes.SELECT_INPUT,
  //   name: "root_cause_type_id",
  //   required: false,
  //   label: `Root Cause Type`,
  //   placeholder: `Select type`,
  //   options: []
  // },
  category_id: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "category_id",
    required: false,
    label: `Category`,
    placeholder: `Select`,
    options: []
  },
  brand_id: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "brand_id",
    required: false,
    label: `Brand`,
    placeholder: `Select`,
    options: []
  },
  id: {
    type: FilterInputTypes.TEXT_INPUT,
    name: "id",
    required: false,
    label: `ID`,
    placeholder: `Enter id`,
  },
  // outlet_dms_code: {
  //   type: FilterInputTypes.TEXT_INPUT,
  //   name: "outlet_dms_code",
  //   required: false,
  //   label: `Outlet DMS Code`,
  //   placeholder: `Enter Code`,
  // },
  // distributor_type_id:{
  //   type: FilterInputTypes.SELECT_INPUT,
  //   name: "distributor_type_id",
  //   required: false,
  //   label: `Distributor type`,
  //   placeholder: `Select`,
  //   options: Utils.prepareDropdownOptions(InputFieldOptions.distributor_type_id)
  // },
  // town_dms_code:{
  //   type: FilterInputTypes.TEXT_INPUT,
  //   name: "town_dms_code",
  //   required: false,
  //   label: `Town DMS Code`,
  //   placeholder: `Enter Code`,
  // },
  title: {
    type: FilterInputTypes.TEXT_INPUT,
    name: "title",
    required: false,
    label: `Title`,
    placeholder: `Enter title`,
  },
  email: {
    type: FilterInputTypes.TEXT_INPUT,
    name: "email",
    required: false,
    label: `Email`,
    placeholder: `Enter email`,
  },
  mobile: {
    type: FilterInputTypes.TEXT_INPUT,
    name: "mobile",
    required: false,
    label: `Mobile`,
    placeholder: `Enter mobile`,
  },
  status: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "status",
    required: false,
    label: `Status`,
    placeholder: `Select Status`,
    options: Utils.prepareDropdownOptions(InputFieldOptions.status)
  },
  // complaint_status: {
  //   type: FilterInputTypes.SELECT_INPUT,
  //   name: "status",
  //   required: false,
  //   label: `Status`,
  //   placeholder: `Select Status`,
  //   options: Utils.prepareDropdownOptions(InputFieldOptions.complaint_status)
  // },
  // program: {
  //   type: FilterInputTypes.SELECT_INPUT,
  //   name: "program",
  //   required: false,
  //   label: `Program`,
  //   placeholder: `Select Program`,
  //   options: Utils.prepareDropdownOptions(InputFieldOptions.program)
  // },
  year: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "year",
    required: false,
    label: `Year`,
    placeholder: `Select Year`,
    options: Utils.prepareDropdownOptions(InputFieldOptions.year)
  },
  timeline: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "time",
    required: false,
    label: `Timeline`,
    placeholder: `Select timeline`,
    options: []
  },
  // outbound_call_category_id: {
  //   type: FilterInputTypes.SELECT_INPUT,
  //   name: "outbound_call_category_id",
  //   required: false,
  //   label: `Outbound Call Category`,
  //   placeholder: `Select`,
  //   options: []
  // },
  // outbound_call_mode_id: {
  //   type: FilterInputTypes.SELECT_INPUT,
  //   name: "outbound_call_mode_id",
  //   required: false,
  //   label: `Outbound Call Mode`,
  //   placeholder: `Select`,
  //   options: []
  // },
  // outbound_call_status_id: {
  //   type: FilterInputTypes.SELECT_INPUT,
  //   name: "outbound_call_status_id",
  //   required: false,
  //   label: `Outbound Call Status`,
  //   placeholder: `Select`,
  //   options: []
  // },
  // outbound_change_request_id: {
  //   type: FilterInputTypes.SELECT_INPUT,
  //   name: "outbound_change_request_id",
  //   required: false,
  //   label: `Outbound Change Request`,
  //   placeholder: `Select`,
  //   options: []
  // },
};