import {regionConstants} from "../components/region/RegionConstants";
import {userConstants} from "../components/user/UserConstants";
import {userGroupConstants} from "../components/user_group/UserGroupConstants";
import {territoryConstants} from "../components/territory/TerritoryConstants";
import {InputFields} from "./InputFields";
import {areaConstants} from "../components/region/AreaConstants";
import {townConstants} from "../components/region/TownConstants";
import {agentGroupConstants} from "../components/user_group/AgentConstants";
import {productConstants} from "../components/region/ProductConstants";
import {channelConstants} from "../components/region/ChannelConstants";
import {outletConstants} from "../components/region/OutletConstants";
import {monitoringDataConstants} from "../components/region/MonitoringDataConstants";
import {competitionMonitoringProductConstants} from "../components/region/CompetitionMonitoringProductConstants";


export const BASEURL = process.env.REACT_APP_BASE_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
export const APP_NAME = process.env.REACT_APP_NAME;

export const SKIP = 10;
export const LIMIT = 10;

export const MenuConfig = {
    home: { lower: "dashboard", upper: "Home"},

    user: {
        lower: "user",
        upper: "User",
        api: "users",
        listTitle: "User List",
        filter: userConstants.filters,
        list: userConstants.listAttributes,
        details: userConstants.detailsAttributes,
        stream: "userList"
    },
    user_group: {
        lower: "user_group",
        upper: "UserGroup",
        api: "user-groups",
        listTitle: "UserGroup List",
        filter: userGroupConstants.filters,
        list: userGroupConstants.listAttributes,
        details: userGroupConstants.detailsAttributes,
        stream: "user_groupList"
    },
    agent: {
        lower: "agent",
        upper: "ASE",
        api: "agents",
        listTitle: "ASE List",
        filter: agentGroupConstants.filters,
        list: agentGroupConstants.listAttributes,
        details: agentGroupConstants.detailsAttributes,
        stream: "agentList"
    },

    region: {
        lower: "region",
        upper: "Region",
        api: "regions",
        listTitle: "Region List",
        filter: regionConstants.filters,
        list: regionConstants.listAttributes,
        details: regionConstants.detailsAttributes,
        stream: "regionList",
        fields: [InputFields.title, InputFields.code, InputFields.status,]
    },
    area: {
        lower: "area",
        upper: "Area",
        api: "areas",
        listTitle: "Area List",
        filter: areaConstants.filters,
        list: areaConstants.listAttributes,
        details: areaConstants.detailsAttributes,
        stream: "areaList",
        fields: [InputFields.title, InputFields.code, InputFields.status,]
    },
    territory: {
        lower: "territory",
        upper: "Territory",
        api: "territories",
        listTitle: "Territory List",
        filter: territoryConstants.filters,
        list: territoryConstants.listAttributes,
        details: territoryConstants.detailsAttributes,
        stream: "territoryList"
    },
    town: {
        lower: "town",
        upper: "Town",
        api: "towns",
        listTitle: "Town List",
        filter: townConstants.filters,
        list: townConstants.listAttributes,
        details: townConstants.detailsAttributes,
        stream: "townList",
        fields: [InputFields.title, InputFields.code, InputFields.status,]
    },

    product: {
        lower: "product",
        upper: "Product",
        api: "products",
        listTitle: "Product List",
        filter: productConstants.filters,
        list: productConstants.listAttributes,
        details: productConstants.detailsAttributes,
        stream: "productList"
    },
    channel: {
        lower: "channel",
        upper: "Channel",
        api: "channels",
        listTitle: "Channel List",
        filter: channelConstants.filters,
        list: channelConstants.listAttributes,
        details: channelConstants.detailsAttributes,
        stream: "channelList"
    },
    outlet: {
        lower: "outlet",
        upper: "Outlet",
        api: "outlets",
        listTitle: "Outlet List",
        filter: outletConstants.filters,
        list: outletConstants.listAttributes,
        details: outletConstants.detailsAttributes,
        stream: "outletList"
    },

    monitoring_data: {
        lower: "monitoring_data",
        upper: "MonitoringData",
        api: "monitoring-data",
        listTitle: "Monitoring Data List",
        filter: monitoringDataConstants.filters,
        list: monitoringDataConstants.listAttributes,
        details: monitoringDataConstants.detailsAttributes,
        stream: "monitoring_dataList"
    },
    competition_monitoring_product: {
        lower: "competition_monitoring_product",
        upper: "CompetitionMonitoringProduct",
        api: "competition-monitoring-products",
        listTitle: "Competition Monitoring Product List",
        filter: competitionMonitoringProductConstants.filters,
        list: competitionMonitoringProductConstants.listAttributes,
        details: competitionMonitoringProductConstants.detailsAttributes,
        stream: "competition_monitoring_productList"
    },
}

