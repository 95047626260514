import {matchRoutes, Route, Routes, useLocation} from "react-router-dom"
import {
    getRouteCreate,
    getRouteDetailsPath,
    getRouteList,
    getRouteUpdatePath,
    routeHome
} from "../../utils/RouterUtils";
import {HomePage} from "../home/HomePage";
import React from "react";
import UserCreatePage from "../user/UserCreatePage";
import {MenuConfig} from "../../utils/Constants";
import CommonListPage from "../common/CommonListPage";
import CommonDetailsPage from "../common/CommonDetailsPage";
import CommonCreatePage from "../common/CommonCreatePage";
import TerritoryCreatePage from "../territory/TerritoryCreatePage";
import CommonCreatePageDynamic from "../common/CommonCreatePageDynamic";

export default function MainWindow() {

    const location = useLocation();
    console.log(location.pathname);

    const menuList = [
            MenuConfig.region, MenuConfig.area, MenuConfig.territory, MenuConfig.town,
            MenuConfig.user, MenuConfig.user_group, MenuConfig.agent,

            MenuConfig.channel, MenuConfig.product, MenuConfig.outlet,
            MenuConfig.monitoring_data, MenuConfig.competition_monitoring_product

        ];

    const createMenuList = [
        MenuConfig.user_group
    ];

    const prepareListRoute = (menuList) => {

        return  menuList.map(e =>
            <Route key={`${e.lower}List`} path={getRouteList(e.lower)}
                   element={<CommonListPage key={`${e.lower}List`} config={e}/>} />)
    };

    const prepareDetailsRoute = (menuList) => {

        return  menuList.map(e =>
            <Route key={`${e.lower}Details`}
                   path={getRouteDetailsPath(e.lower)}
                   element={<CommonDetailsPage key={`${e.lower}Details`}  config={e}/>} />)

    };

    const prepareCreateRoute = (menuList) => {

        return  menuList.map(e =>
            <Route key={`${e.lower}Create`}
                      path={getRouteCreate(e.lower)}
               element= {<CommonCreatePage config={e}/>} />)

    };

    const prepareEditRoute = (menuList) => {

        return  menuList.map(e =>
            <Route key={`${e.lower}Edit`}
               path={getRouteUpdatePath(e.lower)}
               element = {<CommonCreatePage key={`${e.lower}Edit`} config={e} edit />} />)

    };

    return <div style={{overflowY:"auto", height:"100%"}}>
        <Routes>

            <Route path={routeHome} element={<HomePage/>} />
            
            <Route path={getRouteCreate(MenuConfig.user.lower)} element={<UserCreatePage/>}/>
            <Route path={getRouteUpdatePath(MenuConfig.user.lower)} element = {<UserCreatePage edit/>} />
            
            {prepareListRoute(menuList)}
            {prepareEditRoute(createMenuList)}
            {prepareCreateRoute(createMenuList)}

            <Route key={`${MenuConfig.region.lower}Create`}

                   path={getRouteCreate(MenuConfig.region.lower)}
                   element={<CommonCreatePageDynamic config={MenuConfig.region}/>}/>

            <Route key={`${MenuConfig.region.lower}Edit`}

                   path={getRouteUpdatePath(MenuConfig.region.lower)}
                   element = { <CommonCreatePageDynamic edit config={MenuConfig.region}/>} />


            <Route key={`${MenuConfig.territory.lower}Create`}

                   path={getRouteCreate(MenuConfig.territory.lower)}
                   element={<TerritoryCreatePage/>}/>

            <Route key={`${MenuConfig.territory.lower}Edit`}

                   path={getRouteUpdatePath(MenuConfig.territory.lower)}
                   element = { <TerritoryCreatePage edit />} />

            
            {prepareDetailsRoute(menuList)}

            <Route path={"/"} element={<HomePage/>} />
            

        </Routes>
    </div>
}