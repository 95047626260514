import FilterInputItems from "../shared/Filter/FilterInputItems";
import {tableContentType} from "../shared/table/table_utils";

export const productConstants = {
  filters: [
    FilterInputItems.id,
    FilterInputItems.title,
    FilterInputItems.status
  ],
  listAttributes: [
    {
      title: 'ID',
      field: 'id',
      type: tableContentType.ID,
    },
    {
      title: 'Name',
      field: 'name',
      type: tableContentType.TEXT,
    },
    {
      title: 'Packaging',
      field: 'packaging',
      type: tableContentType.TEXT,
    },
    {
      title: 'Weight',
      field: 'weight',
      type: tableContentType.TEXT,
    },
    // {
    //   title: 'Status',
    //   field: 'status',
    //   type: tableContentType.STATUS,
    // },
    {
      title: 'Created At',
      field: 'created_at',
      type: tableContentType.DATE_TIME,
    },
    {
      title: 'Action',
      field: 'action',
      type: tableContentType.ACTION,
    },
  ],
  detailsAttributes: [
    {
      title: 'ID',
      field: 'id',
      type: tableContentType.TEXT,
    },
    {
      title: 'Name',
      field: 'name',
      type: tableContentType.TEXT,
    },
    {
      title: 'Packaging',
      field: 'packaging',
      type: tableContentType.TEXT,
    },
    {
      title: 'Weight',
      field: 'weight',
      type: tableContentType.TEXT,
    },
    // {
    //   title: 'Status',
    //   field: 'status',
    //   type: tableContentType.STATUS,
    // },
    {
      title: 'Created At',
      field: 'created_at',
      type: tableContentType.DATE_TIME,
    },
  ]
}

