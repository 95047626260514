
import {
    AimOutlined,
    AppstoreOutlined,
    BranchesOutlined, ClusterOutlined, DeploymentUnitOutlined,
    EnvironmentOutlined,
    ExperimentOutlined, FormOutlined, GroupOutlined,
    HomeOutlined,
    IdcardOutlined,
    MedicineBoxOutlined, PaperClipOutlined, PushpinOutlined, RobotOutlined,
    ShopOutlined, ShoppingCartOutlined, ShoppingOutlined,
    SolutionOutlined,
    TeamOutlined, TrophyOutlined,
    UserOutlined,
    UserSwitchOutlined,
} from '@ant-design/icons';
import { Menu, Layout } from 'antd';
import React, { useState } from 'react';
import {Link} from "react-router-dom";
import Images from "../../../utils/Images";
import BlocBuilder from "../../../BlocBuilder";
import SettingsBloc from "../../../bloc/SettingsBloc";
import './NavigationMenu.css';
import {MenuConfig} from "../../../utils/Constants";
import {getRouteList, routeHome} from "../../../utils/RouterUtils";

const { Sider } = Layout;

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}
function prepareMenu({config, path, icon }) {
    return {
        label: (<Link key={config.lower+"-link"} to={path} style={{color: "#000000"}}>{config.upper}</Link>),
        key: config.lower,
        icon: icon,
    }
}

const routes = {
    home: {config: MenuConfig.home, path: routeHome, icon: <HomeOutlined/>},

    region: {config: MenuConfig.region, path: getRouteList(MenuConfig.region.lower), icon: <DeploymentUnitOutlined />, menu: "locations"},
    area: {config: MenuConfig.area, path: getRouteList(MenuConfig.area.lower), icon: <DeploymentUnitOutlined />, menu: "locations"},
    territory: {config: MenuConfig.territory, path: getRouteList(MenuConfig.territory.lower), icon: <ClusterOutlined />, menu: "locations"},
    town: {config: MenuConfig.town, path: getRouteList(MenuConfig.town.lower), icon: <DeploymentUnitOutlined />, menu: "locations"},

    user: {config: MenuConfig.user, path: getRouteList(MenuConfig.user.lower), icon: <UserOutlined />, menu: "users"},
    user_group: {config: MenuConfig.user_group, path: getRouteList(MenuConfig.user_group.lower), icon: <TeamOutlined />, menu: "users"},
    agent: {config: MenuConfig.agent, path: getRouteList(MenuConfig.agent.lower), icon: <UserOutlined />, menu: "users"},

    product: {config: MenuConfig.product, path: getRouteList(MenuConfig.product.lower), icon: <PushpinOutlined />, menu: "items"},
    channel: {config: MenuConfig.channel, path: getRouteList(MenuConfig.channel.lower), icon: <PushpinOutlined />, menu: "items"},
    outlet: {config: MenuConfig.outlet, path: getRouteList(MenuConfig.outlet.lower), icon: <PushpinOutlined />, menu: "items"},
    monitoring_data: {config: MenuConfig.monitoring_data, path: getRouteList(MenuConfig.monitoring_data.lower), icon: <PushpinOutlined />, menu: "items"},
    competition_monitoring_product: {config: MenuConfig.competition_monitoring_product, path: getRouteList(MenuConfig.competition_monitoring_product.lower), icon: <PushpinOutlined />, menu: "items"},

}

const items = [
    prepareMenu(routes.home),

    prepareMenu(routes.monitoring_data),

    getItem('Users', 'users', <UserOutlined/>, [
        prepareMenu(routes.user),
        prepareMenu(routes.user_group),
        prepareMenu(routes.agent),
    ]),
    getItem('Locations', 'locations', <EnvironmentOutlined />, [
        prepareMenu(routes.region),
        prepareMenu(routes.area),
        prepareMenu(routes.territory),
        prepareMenu(routes.town),
    ]),
    getItem('Items', 'items', <AppstoreOutlined />, [
        prepareMenu(routes.channel),
        prepareMenu(routes.product),
        prepareMenu(routes.outlet),
        prepareMenu(routes.competition_monitoring_product),
    ]),
];
const NavigationMenu = () => {
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return (
        <BlocBuilder
            subject={SettingsBloc.instance.sideBarCollapsed}
            builder={(snapshot) => {
                return <BlocBuilder
                    subject={SettingsBloc.instance.currentRoute}
                    builder={(snapshotRoute) => {
                        // console.log(snapshotRoute.data)
                        return <BlocBuilder
                            subject={SettingsBloc.instance.currentMenu}
                            builder={(snapshotMenu) => {
                                // console.log(snapshotMenu.data)
                                return <Sider trigger={null} collapsible collapsed={snapshot.data} style={{width:230}}>
                                    <Link key={"home-link"} to={"/dashboard"}>
                                        <div className="side-logo">

                                            <img
                                                style={{marginRight: 0, marginLeft: 32, filter: "brightness(0) invert(1)"}}
                                                width={40}
                                                src={Images.logoUnilever}
                                            />
                                            <img
                                                width={50}
                                                src={Images.logo}
                                            />
                                        </div>
                                    </Link>
                                    <Menu
                                        defaultSelectedKeys={['1']}
                                        defaultOpenKeys={['sub1']}
                                        mode="inline"
                                        theme="dark"
                                        collapsed={collapsed === true ? collapsed: undefined}
                                        items={items}
                                    />
                                </Sider>
                            }}/>
                    }}/>
            }}/>

    );
};
export default NavigationMenu;